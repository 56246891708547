<template>
  <div>
    <div class="club-user-container">
      <div class="club-user-avatar-container">
        <div class="club-user-avatar">
          <el-avatar :size="150" :src="userInfo.photoUrl"></el-avatar>
          <h3 style="margin: 0 auto">{{userInfo.username}}</h3>
          <p>
          <span>
            {{userInfo.numFollowers}} <b>粉丝</b>
          </span>
            <span>
           {{userInfo.numFollowings}} <b>关注</b>
          </span>
          </p>
          <div><b>状态</b>：
            <el-tag size="mini" type="success"  v-if="!userInfo.isBlockedByNetwork">正常</el-tag>
            <el-tag size="mini" type="danger"  v-else>封禁</el-tag>
          </div>
        </div>
      </div>
      <div class="club-user-info-container">
        <div class="club-user-info-title">
          <div><b>姓名</b></div>
          <div><b>twitter</b></div>
          <div><b>instagram</b></div>
          <div><b>注册时间</b></div>
          <div style="border-right: #ccc solid 1px"><b>主页连接</b></div>
        </div>
        <div class="club-user-info-content">
          <div style="flex: 4;background-color: #fff;display: flex" class="club-user-info-content-row">
            <div class="club-user-info-content-row-content">
              <div>{{userInfo.name}}</div>
              <div>{{userInfo.twitter}}</div>
              <div>{{userInfo.instagram}}</div>
              <div>{{userInfo.createTime}}</div>
            </div>
            <div class="club-user-info-content-row-title">
              <div><b>用户ID</b></div>
              <div><b>用户名</b></div>
              <div><b>简介</b></div>
              <div><b>入库时间</b></div>
            </div>
            <div class="club-user-info-content-row-content">
              <div>{{userInfo.userId}}</div>
              <div>{{userInfo.username}}</div>
              <div>{{userInfo.bio}}</div>
              <div><b>{{ userInfo.dataTime }}</b></div>
            </div>
          </div>
          <div style="flex: 1;border-bottom: #ccc solid 1px;line-height: 45px;padding-left: 10px"><el-link :href="userInfo.url">{{userInfo.url}}</el-link></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UserInfoComponent',
  // import引入的组件需要注入到对象中才能使用",
  components: {},
  props: {
    userInfo: Object
  },
  data() {
    // 这里存放数据",
    return {}
  },
  // 监听属性 类似于data概念",
  computed: {},
  // 监控data中的数据变化",
  watch: {},
  // 方法集合",
  methods: {},
  // 生命周期 - 创建之前",数据模型未加载,方法未加载,html模板未加载
  beforeCreate() {
  },

  // 生命周期 - 创建完成（可以访问当前this实例）",数据模型已加载，方法已加载,html模板已加载,html模板未渲染
  created() {

  },
  // 生命周期 - 挂载之前",html模板未渲染
  beforeMount() {

  },

  // 生命周期 - 挂载完成（可以访问DOM元素）",html模板已渲染
  mounted() {

  },

  // 生命周期 - 更新之前",数据模型已更新,html模板未更新
  beforeUpdate() {

  },
  // 生命周期 - 更新之后",数据模型已更新,html模板已更新
  updated() {

  },
  // 生命周期 - 销毁之前",
  beforeDestroy() {

  },
  destroyed() {

  },
  // 生命周期 - 销毁完成",
  // 如果页面有keep-alive缓存功能，这个函数会触发",
  activated() {

  }
}

// "http-get请求": {
//   "prefix": "httpget",
//     "body": [
//     "this.\({",
//     "url: this.\\$http.adornUrl(''),",
//     "method: 'get',",
//     "params: this.\\$http.adornParams({})",
//     "}).then(({ data }) => {",
//     "})"
//   ],
//     "description": "httpGET请求"
// },
// "http-post请求": {
//   "prefix": "httppost",
//     "body": [
//     "this.\({",
//     "url: this.\\$http.adornUrl(''),",
//     "method: 'post',",
//     "data: this.\\$http.adornData(data, false)",
//     "}).then(({ data }) => { });"
//   ],
//     "description": "httpPOST请求"
// }
// }

</script>

<style scoped>
.club-user-container{
  display: flex;
  border-right: #ccc solid 1px;
  border-top: #ccc solid 1px;
  border-left: #ccc solid 1px;
}
.club-user-avatar-container{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 250px;
  border-right: #ccc solid 1px;
  border-bottom: #ccc solid 1px;
  background-color: #F2F6FC;
}
.club-user-avatar{
  text-align: center;
}
.club-user-info-container{
  display: flex;
  width: 100%;
}
.club-user-info-title{
  display: flex;
  text-align: center;
  flex: 1;
  flex-direction: column;
}
.club-user-info-content{
  height: 100%;
  /*background-color: red;*/
  flex: 4;
  display: flex;
  flex-direction: column;
}
.club-user-info-title div{
  flex: 1;
  background-color: #F2F6FC;
  line-height: 45px;
  border-bottom: #ccc solid 1px;
}

.club-user-info-content-row div{
  flex: 1;
}
.club-user-info-content-row-content {
  display: flex;
  flex-direction: column
}
.club-user-info-content-row-content div{
  border-bottom: #ccc solid 1px;
  border-left: #ccc solid 1px;
  flex: 1;
  line-height: 45px;
  padding-left: 10px;
}

.club-user-info-content-row-title {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.club-user-info-content-row-title div{
  border-bottom: #ccc solid 1px;
  border-left: #ccc solid 1px;
  flex: 1;
  line-height: 45px;
  background-color: #F2F6FC;
}
</style>
