import request from '@/plugins/axios'

export function getInvitedInfoByUserId(userId) {
  return request({
    url: `invite/${userId}/invited`,
    method: 'get'
  })
}

export function queryUserListByUserId(userId, params) {
  return request({
    url: `invite/${userId}/invitationList`,
    method: 'get',
    params
  })
}
