<template>
  <div class="app-container" style=" margin-top: -8px">
    <div class="searchList">
      <el-form :inline="true" :model="searchData" class="demo-form-inline">
        <el-form-item label="ID">
          <el-input v-model="searchData.userId" placeholder="用户ID"></el-input>
        </el-form-item>
        <el-form-item label="用户名">
          <el-input
            v-model="searchData.username"
            placeholder="用户名"
          ></el-input>
        </el-form-item>
        <el-form-item label="用户姓名">
          <el-input v-model="searchData.name" placeholder="用户姓名"></el-input>
        </el-form-item>
        <el-form-item label="简介">
          <el-input v-model="searchData.bio" placeholder="简介"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      border
      fit
      highlight-current-row
      style="width: 100%"
      :height="pageHeight - 200"
      class="tableList"
    >
      <el-table-column prop="photoUrl" label="头像" width="100">
        <template slot-scope="scope">
          <el-avatar
            shape="square"
            size="small"
            :src="scope.row.photoUrl"
          ></el-avatar>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="用户姓名">
        <template slot-scope="scope">
          <div v-html="scope.row.name"></div>
        </template>
      </el-table-column>
      <el-table-column prop="username" label="用户名"></el-table-column>
      <el-table-column prop="twitter" label="推特用户名"></el-table-column>
      <el-table-column
        prop="instagram"
        label="instagram用户名"
      ></el-table-column>
      <el-table-column prop="numFollowers" label="粉丝数"></el-table-column>
      <el-table-column prop="numFollowings" label="关注数"></el-table-column>
      <el-table-column
        prop="createTime"
        label="注册时间"
        width="155px"
      ></el-table-column>
      <el-table-column prop="invitedUserId" label="邀请人ID"></el-table-column>

      <!--      <el-table-column-->
      <!--        prop='invitedByClub'-->
      <!--        label='被哪个club邀请'>-->
      <!--      </el-table-column>-->

      <!--      <el-table-column-->
      <!--        prop='url'-->
      <!--        width='380'-->
      <!--        label='主页链接'-->
      <!--        show-overflow-tooltip>-->
      <!--        <template slot-scope='scope'>-->
      <!--          <el-link :href='scope.row.url'>{{ scope.row.photoUrl }}</el-link>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <!--      <el-table-column-->
      <!--        prop='isBlockedByNetwork'-->
      <!--        label='是否被网络禁止'-->
      <!--        width='100'>-->
      <!--        <template slot-scope='scope'>-->
      <!--          {{ scope.row.status }}-->
      <!--          <el-tag-->
      <!--            :type="!scope.row.isBlockedByNetwork ? 'success' : 'danger'"-->
      <!--            disable-transitions>{{ !scope.row.status ? '正常' : '封禁' }}-->
      <!--          </el-tag>-->
      <!--        </template>-->
      <!--      </el-table-column>-->

      <!--      <el-table-column-->
      <!--        prop='mutualFollowsCount'-->
      <!--        label='相互关注数'>-->
      <!--      </el-table-column>-->

      <!--      <el-table-column-->
      <!--        prop='mutualFollows'-->
      <!--        label='相互关注人'>-->
      <!--      </el-table-column>-->

      <!--      <el-table-column-->
      <!--        prop='upcomingEvents'-->
      <!--        label='接下来的活动'>-->
      <!--      </el-table-column>-->

      <!--      <el-table-column-->
      <!--        prop='notificationType'-->
      <!--        label='通知类型'>-->
      <!--      </el-table-column>-->
      <!--      <el-table-column-->
      <!--        prop='canReceiveDirectPayment'-->
      <!--        label='可以收到直接付款'>-->
      <!--      </el-table-column>-->

      <!--      <el-table-column-->
      <!--        prop='directPaymentFeeRate'-->
      <!--        label='直接付款收费率'>-->
      <!--      </el-table-column>-->
      <!--      <el-table-column-->
      <!--        prop='directPaymentFeeFixed'-->
      <!--        label='直接付款费用固定'>-->
      <!--      </el-table-column>-->

      <!--      <el-table-column-->
      <!--        prop='bioLinks'-->
      <!--        label='简介列表'>-->
      <!--      </el-table-column>-->
      <!--      <el-table-column-->
      <!--        prop='canChat'-->
      <!--        label='可以聊天'>-->
      <!--      </el-table-column>-->
      <el-table-column prop="bio" label="简介" show-overflow-tooltip>
        <template slot-scope="scope">
          <div v-html="scope.row.bio"></div>
        </template>
      </el-table-column>
      <!--      <el-table-column-->
      <!--        prop='dataTime'-->
      <!--        label='入库时间'>-->
      <!--      </el-table-column>-->

      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            style="color: #E6A23C"
            @click.native.prevent="handleUserInfo(scope.row.id)"
            type="text"
            size="small"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block-pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="page"
        :page-size="limit"
        :page-sizes="[limit, limit * 2, limit * 4, 100]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <!--用户详情 开始-->
    <el-dialog title="俱乐部用户详情" :visible.sync="showUserInfo" width="70%">
      <UserInfo :userId.sync="userId"></UserInfo>
    </el-dialog>
    <!--用户详情 结束-->
  </div>
</template>

<script>
import { page } from '@/api/clubhouseUser'
import UserInfo from '@/components/userInfo/index'

export default {
  name: 'ClubhouseUser',
  // import引入的组件需要注入到对象中才能使用",
  components: { UserInfo },
  data() {
    // 这里存放数据",
    return {
      tableData: [],
      searchData: {},
      page: 1,
      limit: 10,
      total: 0,
      showUserInfo: false,
      userId: undefined
    }
  },
  // 监听属性 类似于data概念",
  computed: {
    pageHeight() {
      return this.$store.state.settings.windowHeight
    }
  },
  // 监控data中的数据变化",
  watch: {},
  // 方法集合",
  methods: {
    initPage() {
      page(this.page, this.limit, this.searchData).then(resp => {
        this.tableData = resp.items
        this.total = resp.all_count
      })
    },
    handleSizeChange(val) {
      // 当每页显示条数发生变化时
      this.limit = val
      this.initPage()
    },
    handleCurrentChange(val) {
      // 当页码发生变化时
      this.page = val
      this.initPage()
    },
    handleUserInfo(id) {
      this.showUserInfo = true
      this.userId = id
    },
    onSearch() {
      this.page = 1
      this.initPage()
    }
  },
  // 生命周期 - 创建之前",数据模型未加载,方法未加载,html模板未加载
  beforeCreate() {},

  // 生命周期 - 创建完成（可以访问当前this实例）",数据模型已加载，方法已加载,html模板已加载,html模板未渲染
  created() {
    this.initPage()
  },
  // 生命周期 - 挂载之前",html模板未渲染
  beforeMount() {},

  // 生命周期 - 挂载完成（可以访问DOM元素）",html模板已渲染
  mounted() {},

  // 生命周期 - 更新之前",数据模型已更新,html模板未更新
  beforeUpdate() {},
  // 生命周期 - 更新之后",数据模型已更新,html模板已更新
  updated() {},
  // 生命周期 - 销毁之前",
  beforeDestroy() {},
  destroyed() {},
  // 生命周期 - 销毁完成",
  // 如果页面有keep-alive缓存功能，这个函数会触发",
  activated() {}
}

// "http-get请求": {
//   "prefix": "httpget",
//     "body": [
//     "this.\({",
//     "url: this.\\$http.adornUrl(''),",
//     "method: 'get',",
//     "params: this.\\$http.adornParams({})",
//     "}).then(({ data }) => {",
//     "})"
//   ],
//     "description": "httpGET请求"
// },
// "http-post请求": {
//   "prefix": "httppost",
//     "body": [
//     "this.\({",
//     "url: this.\\$http.adornUrl(''),",
//     "method: 'post',",
//     "data: this.\\$http.adornData(data, false)",
//     "}).then(({ data }) => { });"
//   ],
//     "description": "httpPOST请求"
// }
// }
</script>

<style lang="scss" scoped>
.block-pagination {
  margin-top: 10px;
  text-align: center;
}
.searchList {
  padding: 10px;
  box-shadow: 0 0 5px 0 rgb(82 63 105 / 20%);
  background: #fff;
  border: 1px solid #ebeef5;
  border-radius: 10px;
  margin-bottom: 15px;
  height: 64px;
}
.tableList {
  padding: 10px;
  box-shadow: 0 0 5px 0 rgb(82 63 105 / 20%);
  background: #fff;
  border: 1px solid #ebeef5;
  border-radius: 10px;
  margin-bottom: 15px;
}
</style>
