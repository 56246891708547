<template>
  <div>
    <h1>当前用户邀请的用户</h1>
    <el-table
      :data='tableData'
      style='width: 100%'>
      <el-table-column
        prop='photoUrl'
        label='头像'
        width='100'>
        <template slot-scope='scope'>
          <el-avatar shape="square" size="small" :src="scope.row.photoUrl"></el-avatar>
        </template>
      </el-table-column>
      <el-table-column
        prop='name'
        label='用户姓名'>
      </el-table-column>
      <el-table-column
        prop='username'
        label='用户名'>
      </el-table-column>
      <el-table-column
        prop='twitter'
        label='推特用户名'>
      </el-table-column>
      <el-table-column
        prop='instagram'
        label='instagram用户名'>
      </el-table-column>
      <el-table-column
        prop='numFollowers'
        label='粉丝数'
        width='80'>
      </el-table-column>
      <el-table-column
        prop='numFollowings'
        label='关注数'
        width='80'>
      </el-table-column>
      <el-table-column
        prop='createTime'
        label='注册时间'>
      </el-table-column>
<!--      <el-table-column
        prop='invitedUserId'
        label='邀请ID'>
      </el-table-column>-->

      <el-table-column
        prop='invitedByClub'
        label='被哪个club邀请'>
      </el-table-column>

      <el-table-column
        prop='url'
        width="380"
        label='主页链接'>
        <template slot-scope='scope'>
          <el-link :href="scope.row.url">{{scope.row.photoUrl}}</el-link>
        </template>
      </el-table-column>
      <el-table-column
        prop='isBlockedByNetwork'
        label='是否被网络禁止'
        width='100'>
        <template slot-scope='scope' >
          <el-tag
            :type="!scope.row.isBlockedByNetwork ? 'success' : 'danger'"
            disable-transitions>{{ !scope.row.isBlockedByNetwork ? '正常' : '封禁' }}
          </el-tag>
        </template>
      </el-table-column>

     <!-- <el-table-column
        prop='mutualFollowsCount'
        label='相互关注数'>
      </el-table-column>

      <el-table-column
        prop='mutualFollows'
        label='相互关注人'>
      </el-table-column>

      <el-table-column
        prop='upcomingEvents'
        label='接下来的活动'>
      </el-table-column>

      <el-table-column
        prop='notificationType'
        label='通知类型'>
      </el-table-column>
      <el-table-column
        prop='canReceiveDirectPayment'
        label='可以收到直接付款'>
      </el-table-column>

      <el-table-column
        prop='directPaymentFeeRate'
        label='直接付款收费率'>
      </el-table-column>
      <el-table-column
        prop='directPaymentFeeFixed'
        label='直接付款费用固定'>
      </el-table-column>

      <el-table-column
        prop='bioLinks'
        label='简介列表'>
      </el-table-column>
      <el-table-column
        prop='canChat'
        label='可以聊天'>
      </el-table-column>
      <el-table-column
        prop='dataTime'
        label='入库时间'>
      </el-table-column>
      <el-table-column
        prop='bio'
        label='简介'>
      </el-table-column>-->
<!--      <el-table-column
        fixed='right'
        label='操作'>
        <template slot-scope='scope'>
          <el-button
            style='color: #E6A23C'
            @click.native.prevent='handleUserInfo(scope.row.id)'
            type='text'
            size='small'>
            详情
          </el-button>
        </template>
      </el-table-column>-->
    </el-table>
    <div class='block'>
      <el-pagination
        @size-change='handleSizeChange'
        @current-change='handleCurrentChange'
        :current-page.sync='page'
        :page-size='limit'
        :page-sizes='[limit, 20, 50, 100]'
        layout='total, sizes, prev, pager, next, jumper'
        :total='total'>
      </el-pagination>
      </div>
  </div>
</template>

<script>

import { queryUserListByUserId } from '@/api/invited'

export default {
  name: 'PageTableComponent',
  // import引入的组件需要注入到对象中才能使用",
  components: {},
  props: {
    userId: Number
  },
  data() {
    // 这里存放数据",
    return {
      tableData: [],
      page: 1,
      limit: 5,
      total: 0
    }
  },
  // 监听属性 类似于data概念",
  computed: {},
  // 监控data中的数据变化",
  watch: {
    userId: {
      immediate: true,
      handler: function (userId) {
        if (userId) {
          this.fetchPageData()
        }
      }
    }
  },
  // 方法集合",
  methods: {
    fetchPageData() {
      this.initPage()
    },
    handleSizeChange(val) {
      this.limit = val
      this.initPage()
    },
    handleCurrentChange(val) {
      this.page = val
      this.initPage()
    },
    initPage() {
      queryUserListByUserId(this.userId, { pageIndex: this.page, pageSize: this.limit }).then(resp => {
        console.info(resp)
        this.tableData = resp.items
        this.total = resp.all_count
      })
    }
  },
  // 生命周期 - 创建之前",数据模型未加载,方法未加载,html模板未加载
  beforeCreate() {
  },

  // 生命周期 - 创建完成（可以访问当前this实例）",数据模型已加载，方法已加载,html模板已加载,html模板未渲染
  created() {

  },
  // 生命周期 - 挂载之前",html模板未渲染
  beforeMount() {

  },

  // 生命周期 - 挂载完成（可以访问DOM元素）",html模板已渲染
  mounted() {},

  // 生命周期 - 更新之前",数据模型已更新,html模板未更新
  beforeUpdate() {

  },
  // 生命周期 - 更新之后",数据模型已更新,html模板已更新
  updated() {

  },
  // 生命周期 - 销毁之前",
  beforeDestroy() {

  },
  destroyed() {

  },
  // 生命周期 - 销毁完成",
  // 如果页面有keep-alive缓存功能，这个函数会触发",
  activated() {

  }
}

</script>

<style>

</style>
