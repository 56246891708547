<template>
  <div>
    <h1>当前用户所在的club</h1><el-table
    :data='tableData'
    style='width: 100%'>
    <el-table-column
      prop='photoUrl'
      label='头像'
      width='100'>
      <template slot-scope='scope'>
        <el-avatar shape="square" size="small" :src="scope.row.photoUrl"></el-avatar>
      </template>
    </el-table-column>
    <el-table-column
      prop='name'
      label='club名称'>
    </el-table-column>
    <el-table-column
      prop='description'
      label='简介'
      width='300'
      show-overflow-tooltip>
    </el-table-column>
    <el-table-column
      prop='numMembers'
      label='群成员数'
      width='80'>
    </el-table-column>
    <el-table-column
      prop='numOnline'
      label='在线人数'
      width='80'>
    </el-table-column>
    <el-table-column
      prop='enablePrivate'
      label='启用私人'
      width='80'>
      <template slot-scope='scope' >
        <el-tag
          :type="scope.row.enablePrivate ? 'success' : 'danger'"
          disable-transitions>{{ scope.row.enablePrivate ? '是' : '否' }}
        </el-tag>
      </template>
    </el-table-column>
    <el-table-column
      prop='isFollowAllowed'
      label='可关注'
      width='80'>
      <template slot-scope='scope' >
        <el-tag
          :type="scope.row.isFollowAllowed ? 'success' : 'danger'"
          disable-transitions>{{ scope.row.isFollowAllowed ? '是' : '否' }}
        </el-tag>
      </template>
    </el-table-column>
    <el-table-column
     prop='isCommunity'
     label='是否社区'
     width='80'>
     <template slot-scope='scope' >
       <el-tag
         :type="scope.row.isCommunity ? 'success' : 'danger'"
         disable-transitions>{{ scope.row.isCommunity ? '社区' : '非社区' }}
       </el-tag>
     </template>
   </el-table-column>
    <el-table-column
      prop='url'
      width="380"
      label='群连接'>
      <template slot-scope='scope'>
        <el-link :href="scope.row.url">{{scope.row.photoUrl}}</el-link>
      </template>
    </el-table-column>
 <!--   <el-table-column
      prop='isBlockedByNetwork'
      label='是否被网络禁止'
      width='100'>
      <template slot-scope='scope' >
        {{scope.row.status}}
        <el-tag
          :type="!scope.row.isBlockedByNetwork ? 'success' : 'danger'"
          disable-transitions>{{ !scope.row.status ? '正常' : '封禁' }}
        </el-tag>
      </template>
    </el-table-column>-->
  </el-table>
    <div class='block'>
      <el-pagination
        @size-change='handleSizeChange'
        @current-change='handleCurrentChange'
        :current-page.sync='page'
        :page-size='limit'
        :page-sizes='[limit, 20, 50, 100]'
        layout='total, sizes, prev, pager, next, jumper'
        :total='total'>
      </el-pagination>
    </div>
  </div>
</template>

<script>

import { queryPage } from '@/api/clubUser'

export default {
  name: 'ClubPageComponent',
  // import引入的组件需要注入到对象中才能使用",
  components: {},
  props: {
    userId: Number
  },
  data() {
    // 这里存放数据",
    return {
      tableData: [],
      page: 1,
      limit: 5,
      total: 0
    }
  },
  // 监听属性 类似于data概念",
  computed: {},
  // 监控data中的数据变化",
  watch: {
    userId: {
      immediate: true,
      handler: function (userId) {
        if (userId) {
          this.fetchPageData()
        }
      }
    }
  },
  // 方法集合",
  methods: {
    fetchPageData() {
      queryPage(this.userId, { pageIndex: this.page, pageSize: this.limit }).then(resp => {
        console.info(resp)
        this.tableData = resp.items
        this.total = resp.all_count
      })
    },
    handleSizeChange(val) {
      this.limit = val
      this.fetchPageData()
    },
    handleCurrentChange(val) {
      this.page = val
      this.fetchPageData()
    }
  },
  // 生命周期 - 创建之前",数据模型未加载,方法未加载,html模板未加载
  beforeCreate() {
  },

  // 生命周期 - 创建完成（可以访问当前this实例）",数据模型已加载，方法已加载,html模板已加载,html模板未渲染
  created() {

  },
  // 生命周期 - 挂载之前",html模板未渲染
  beforeMount() {

  },

  // 生命周期 - 挂载完成（可以访问DOM元素）",html模板已渲染
  mounted() {

  },

  // 生命周期 - 更新之前",数据模型已更新,html模板未更新
  beforeUpdate() {

  },
  // 生命周期 - 更新之后",数据模型已更新,html模板已更新
  updated() {

  },
  // 生命周期 - 销毁之前",
  beforeDestroy() {

  },
  destroyed() {

  },
  // 生命周期 - 销毁完成",
  // 如果页面有keep-alive缓存功能，这个函数会触发",
  activated() {

  }
}

// "http-get请求": {
//   "prefix": "httpget",
//     "body": [
//     "this.\({",
//     "url: this.\\$http.adornUrl(''),",
//     "method: 'get',",
//     "params: this.\\$http.adornParams({})",
//     "}).then(({ data }) => {",
//     "})"
//   ],
//     "description": "httpGET请求"
// },
// "http-post请求": {
//   "prefix": "httppost",
//     "body": [
//     "this.\({",
//     "url: this.\\$http.adornUrl(''),",
//     "method: 'post',",
//     "data: this.\\$http.adornData(data, false)",
//     "}).then(({ data }) => { });"
//   ],
//     "description": "httpPOST请求"
// }
// }

</script>

<style>

</style>
