<template>
  <div style="max-height: 600px;overflow-y: auto">
    <h1>当前用户信息</h1>
    <UserInfoComponent :user-info="mainUserInfo"></UserInfoComponent>
    <div v-if="upInvitedUserInfo !== undefined">
      <h1>上级邀请用户信息</h1>
      <UserInfoComponent :user-info="upInvitedUserInfo"></UserInfoComponent>
    </div>
    <PageTableComponent :user-id="mainUserInfo.userId"></PageTableComponent>
    <ClubPageComponent :userId="mainUserInfo.userId"></ClubPageComponent>
  </div>
</template>

<script>

import { queryById } from '@/api/clubhouseUser'
import UserInfoComponent from '@/components/userInfo/UserInfoComponent'
import { getInvitedInfoByUserId } from '@/api/invited'
import PageTableComponent from '@/components/userInfo/PageTableComponent'
import ClubPageComponent from '@/components/userInfo/ClubPageComponent'

export default {
  name: 'UserInfo',
  // import引入的组件需要注入到对象中才能使用",
  components: { ClubPageComponent, PageTableComponent, UserInfoComponent },
  props: {
    userId: Number
  },
  data() {
    // 这里存放数据",
    return {
      mainUserInfo: {},
      upInvitedUserInfo: {}
    }
  },
  // 监听属性 类似于data概念",
  computed: {},
  // 监控data中的数据变化",
  watch: {
    userId: {
      immediate: true,
      handler: function (userId) {
        this.mainUserInfo = {}
        this.upInvitedUserInfo = {}
        if (userId) {
          this.fetchData()
        }
      }
    }
  },
  // 方法集合",
  methods: {
    async fetchData() {
      await queryById(this.userId).then(resp => {
        this.mainUserInfo = resp
      })
      await getInvitedInfoByUserId(this.mainUserInfo.userId).then(resp => {
        console.info(resp)
        this.upInvitedUserInfo = resp
      })
    }
  },
  // 生命周期 - 创建之前",数据模型未加载,方法未加载,html模板未加载
  beforeCreate() {
  },

  // 生命周期 - 创建完成（可以访问当前this实例）",数据模型已加载，方法已加载,html模板已加载,html模板未渲染
  created() {
    this.mainUserInfo = {}
  },
  // 生命周期 - 挂载之前",html模板未渲染
  beforeMount() {

  },

  // 生命周期 - 挂载完成（可以访问DOM元素）",html模板已渲染
  mounted() {

  },

  // 生命周期 - 更新之前",数据模型已更新,html模板未更新
  beforeUpdate() {

  },
  // 生命周期 - 更新之后",数据模型已更新,html模板已更新
  updated() {

  },
  // 生命周期 - 销毁之前",
  beforeDestroy() {

  },
  destroyed() {

  },
  // 生命周期 - 销毁完成",
  // 如果页面有keep-alive缓存功能，这个函数会触发",
  activated() {

  }
}

</script>

<style scoped>

</style>
